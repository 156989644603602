import React from "react";
import team001 from "../../images/t6.jpg";
// import { Link } from 'gatsby-plugin-modal-routing'

import ConditionalLayout from "../../components/ConditionalLayout";
// import SEO from "../../components/seo"

const SecondPage = () => (
  <ConditionalLayout>
    <h3 className="title-3">Greg Ebbeck</h3>
    <h4 className="">Director</h4>
    <div className="card">
      <div className="card-body">
        <div className="team-img">
          <img alt="About team" className="img-fluid" src={team001} />
        </div>
        <p>
          A third-generation cattleman Greg has over 50 years hands on
          experience breeding top class cattle on his Six Star Speckle Park
          property in Bowral NSW.{" "}
        </p>
        <p>
          Back in 2006 Greg was instrumental in identifying and introducing the
          Speckle Park breed from Canada into the Australian market. Holding
          previous positions on the board of the Australian Poll Hereford
          Society of Australia, Greg was responsible for establishing the
          Australian and New Zealand arm of Speckle Park Inc and was a Director
          of the Inaugural Speckle Park International Board and Chairman of the
          SPI Board from 2014–2018.{" "}
        </p>
        <p>
          Greg has bred and exhibited hundreds of champion cattle at major
          livestock shows and carcass competitions here and overseas, as well as
          exported and imported genetics and live cattle to all parts of the
          globe.{" "}
        </p>
        <p>
          Greg is a highly regarded international judge of beef cattle which has
          twice seen him being appointed to officiate over the world-famous
          Canadian Agribition Show’s All Breeds Champion of Champion sections.{" "}
        </p>
        <p>
          Greg has a lifelong passion for the development of beef cattle
          genetics in order to advance the growth of the Australian Beef
          Industry and is excited to be part of the board of Speckle Park Group
          and advance the SPKL brand throughout the world.
        </p>
      </div>
    </div>
  </ConditionalLayout>
);

export default SecondPage;
